import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Section from "../../../components/Section";
import ButtonPrimary from "../../../components/ButtonPrimary";
import Badge from "../../../components/Badge";
import BannerBG from "../../../assets/images/banner-bg.png";
import BannerLine from "../../../assets/images/lineHorizontal.png";

const SectionStyled = styled(Section)`
  /* background: linear-gradient(to bottom, #fff, #f2f4f6); */
  overflow: hidden;
`;

const LineContainer = styled.div`
  margin-top: -1vw;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }
`;

const BadgeContainer = styled.div`
  position: absolute;
  left: 80%;
  top: -14px;
  z-index: 1;
`;

const Banner = () => {

  const data = useStaticQuery(graphql`
    query {
      wordpressAcfSeller {
        acf {
          banner {
            text
            title
          }
        }
      }
    }
  `);

  const title = data.wordpressAcfSeller.acf.banner.title;

  const text = data.wordpressAcfSeller.acf.banner.text;

  return (
    <>
      <SectionStyled>
        <div className="position-relative">
          <Container>
            <div
              className="text-center position-relative"
              css={`
                z-index: 10;
              `}
            >
              <img src={BannerBG} alt="Banner" className="img-fluid" />
            </div>
          </Container>
          <LineContainer>
            <img src={BannerLine} alt="Banner" className="w-100  img-fluid" />
          </LineContainer>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ span: "8", offset: "2" }}>
                <div className="text-center mt-5">
                  <h2
                    css={`
                      font-size: 45px;
                      line-height: 55px;
                    `}
                  >
                    {title}
                  </h2>
                  <p className="mb-5 mt-3">
                    {text}
                  </p>

                  <div>
                    <a href="https://www.wemena.com">
                    <ButtonPrimary className="position-relative">
                      <BadgeContainer className="d-none d-sm-block">
                        <Badge inverse>1k already joined</Badge>
                      </BadgeContainer>
                      List your products for free
                    </ButtonPrimary>
                    </a>
                  </div>

                  <p className="text-muted mt-4">
                    100% Secure and Free. No Credit Card Required.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </SectionStyled>
    </>
  );
};

export default Banner;
