import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Accordion } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import { theme } from "../../../utils";

const AccordionContainer = styled.div`
  .accordion-header {
    position: relative;
    padding: 0;
    transition: all 0.3s;
    font-weight: 400;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding-right: 2.25rem;
    .icon {
      margin-left: 1.5rem;
      color: ${theme.primary};
      transition: all 0.3s;
      position: absolute;
      right: 0;
      font-size: 1.25rem;
    }
  }
`;

const AccordionHeader = ({ className, children, active, ...rest }) => {
  return (
    <Accordion.Toggle
      as="div"
      className={`h5 accordion-header ${className}`}
      {...rest}
    >
      {children}
      {active ? <FaMinus className="icon" /> : <FaPlus className="icon" />}
    </Accordion.Toggle>
  );
};

const FAQAccordions = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          faq {
            answer
            question
          }
        }
      }
    }
  `);

  const faqs = data.wordpressAcfHome.acf.faq;

  const [activeItem, setActiveItem] = useState(0);

  const toggleActiveItem = key => {
    if (activeItem === key) {
      setActiveItem(null);
    } else {
      setActiveItem(key);
    }
  };

  return (
    <>
      <AccordionContainer>
        <Accordion defaultActiveKey={activeItem}>
          {faqs.map((faq, i) => (
            <div className="mb-4 border-bottom" key={i}>
              <AccordionHeader
                eventKey={i}
                onClick={() => toggleActiveItem(i)}
                className={`pb-4 mb-0`}
                active={activeItem === i}
              >
                {faq.question}
              </AccordionHeader>

              <Accordion.Collapse eventKey={i}>
                
                <div className="pb-4"
                dangerouslySetInnerHTML={{
                  __html: faq.answer
                }}></div>
                
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </AccordionContainer>
    </>
  );
};

export default FAQAccordions;
