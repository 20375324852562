import React from "react";
import styled from "styled-components";
import {useStaticQuery, graphql} from "gatsby";
import { rgba } from "polished";
import { FaStar } from "react-icons/fa";
import Img from "gatsby-image";
import { Container, Card, Row, Col } from "react-bootstrap";
import Rating from "react-rating";
import Section from "../../../components/Section";
import ButtonPrimary from "../../../components/ButtonPrimary";
import BannerBG from "../../../assets/images/banner-bg.png";
import { theme, breakpoints } from "../../../utils";
import Bar from "../../../components/Bar";
import Badge from "../../../components/Badge";

const SectionStyled = styled(Section)`
  background: linear-gradient(to bottom, #fff, #f2f4f6);
  overflow: hidden;
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    background: url(${BannerBG}) center no-repeat;
    content: "";
    position: absolute;
    bottom: -37%;
    right: 0;
    background-size: contain;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    filter: blur(2px);
  }

  @media (min-width: ${breakpoints.md}px) {
    &:after {
      width: 250%;
    }
  }
  .card {
    z-index: 1;
  }
`;

const BadgeContainer = styled.div`
  position: absolute;
  left: 80%;
  top: -14px;
  z-index: 1;
`;

const MainHeading = styled.div`
  font-size: 45px;
  line-height: 55px;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 32px;
    line-height: 45px !important;
  }
`;

const Banner = () => {

  const data = useStaticQuery(graphql`
    query {
      allWordpressAcfProduct {
        nodes {
          acf {
            price
            size
            title
            image {
              localFile {
                childImageSharp {
                  fixed(width: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      wordpressAcfHome {
        acf {
          banner {
            title
            text
            products {
              post_title
            }
          }
        }
      }
    }
  `);

  const title =
    data.wordpressAcfHome.acf.banner.title;

  
  const text =
    data.wordpressAcfHome.acf.banner.text;

  const allProducts =
    data.allWordpressAcfProduct.nodes;

  const bannerProducts =
    data.wordpressAcfHome.acf.banner.products;

  const productsArr = allProducts.filter(pr => {
    const prTitle  = pr.acf.title;
    return bannerProducts.filter(prB => prB.post_title === prTitle).length > 0;
  });

  return (
    <>
      <SectionStyled>
        <Container>
          <Row>
            <Col lg="6" className="d-none d-md-block">
              <CardContainer
                css={`
                  overflow: hidden;
                  padding: 70px 0;
                `}
              >
                <div>
                  <Row>
                    {productsArr.map(({ acf: product }, i) => (
                      <Col md="4" key={i}>
                        <Card className="mb-4 mb-md-0">
                          <div
                            className="d-flex justify-content-center align-items-center p-3"
                            css={`
                              height: 150px;
                            `}
                          >
                            <Img
                              alt={product.title}
                              fixed={
                                product.image.localFile.childImageSharp.fixed
                              }
                            />
                          </div>
                          <Card.Body>
                            <Bar />
                            <Bar width="50%" className="my-2" />
                            <Bar bg={theme.primary} width="25%" />
                            <div className="mt-3">
                              <Rating
                                initialRating={4.5}
                                readonly
                                emptySymbol={
                                  <FaStar color={rgba(theme.dark, 0.125)} />
                                }
                                fullSymbol={<FaStar color="#F5BB41" />}
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </CardContainer>
            </Col>
            <Col lg="6">
              <div className="h-100 d-flex flex-column justify-content-center p-3">
                <MainHeading>{title}</MainHeading>
                {/* <h1
                  css={`
                    font-size: 45px;
                    line-height: 55px;
                  `}
                >
                  {title}
                </h1> */}
                <p className="mb-4 mt-3">{text}</p>

                <div class="d-sm-block d-md-none mb-3 wistia_embed wistia_async_e7erzh3z7x popover=true popoverAnimateThumbnail=true popoverContent=link">
                  <a href="#">
                    <ButtonPrimary className="position-relative">
                    <i class="fa fa-play"></i>Watch How It Works
                    </ButtonPrimary>
                  </a>
                </div>

                <div>
                  <a href="https://www.wemena.com">
                    <ButtonPrimary className="position-relative">
                      <BadgeContainer className="d-none d-sm-block">
                        <Badge inverse>1k already joined</Badge>
                      </BadgeContainer>
                      Go To Marketplace
                    </ButtonPrimary>
                  </a>
                </div>

                <p className="text-muted mt-4">
                  100% Secure and Free. No Credit Card Required.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Banner;
