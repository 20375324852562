import React from 'react';
import {Button} from 'react-bootstrap';
import styled from 'styled-components';
import PlayVideo from '../../assets/images/playvideo.png';

const ButtonStyled = styled(Button)`
  &.btn-trans {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: nowrap;
    text-overflow: clip;
  }
`;

const ButtonArrow = React.memo(({className, children, ...rest}) => {
  return (
    <ButtonStyled className={`btn-trans ${className}`} {...rest}>
      <div className="d-flex align-items-center text-primary">
        <img src={PlayVideo} className="mr-3" alt="button arrow"/>
        <div> {children}</div>
      </div>
    </ButtonStyled>
  );
});


export default ButtonArrow
