import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Section from "../../../components/Section";
import { breakpoints} from "../../../utils";
import ImgPhonetab from "../../../assets/images/phoneTab.png";
import ImgPhoneHand from "../../../assets/images/phoneHand.png";


const ContainerStyled = styled(Container)`
  margin-top: -100px;
`;

const SectionStyled = styled(Section)`
  @media (min-width: ${breakpoints.md}px) {
    position: relative;
    overflow: hidden;
    padding-right: 30%;
  }
`;

const ImageContainer = styled.div`
  background: ${({ img }) => `url(${img}) center no-repeat`};
  background-size: contain;
  position: absolute;
  bottom: 0;
  right: 0;
`;


const Platform = () => {
  
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfSeller {
        acf {
          platform {
            text
            title
            web_or_mobile
          }
        }
      }
    }
  `);

  const platform = data.wordpressAcfSeller.acf.platform;
  const webPl = platform.filter(pl => pl.web_or_mobile ==="web")[0];
  const mobilePl = platform.filter(pl => pl.web_or_mobile ==="mobile")[0];


  return (
    <ContainerStyled className="">
      <div
        css={`
          position: relative;
          z-index: 10;
        `}
      >
        <Row className="">
          <Col lg="6">
            <SectionStyled
              inside
              className="bg-white rounded-lg shadow-lg mb-5 mb-lg-0"
            >
              <div
                className="pr-lg-5 mb-5 pb-3 text-md-left text-center "
                css={`
                  position: relative;
                  z-index: 1;
                `}
              >
                <p className="text-primary mb-1 text-uppercase">web portal</p>
                <h3
                  className="text-info my-4"
                  css={`
                    font-size: 32px;
                  `}
                >
                  {webPl.title}
                </h3>
                <p>{webPl.text}</p>
              </div>
              <ImageContainer
                className="d-none d-md-block"
                css={`
                  bottom: 15%;
                  right: -40%;
                `}
              >
                <img src={ImgPhonetab} alt="Phone" />
              </ImageContainer>
            </SectionStyled>
          </Col>
          <Col lg="6">
            <SectionStyled inside className="bg-white rounded-lg shadow-lg">
              <div
                className="pr-lg-5 mb-5 pb-3 text-md-left text-center "
                css={`
                  position: relative;
                  z-index: 1;
                `}
              >
                <p className="text-primary mb-1 text-uppercase">Mobile App</p>
                <h3
                  className="text-info my-4"
                  css={`
                    font-size: 32px;
                  `}
                >
                  {mobilePl.title}
                </h3>
                <p>{mobilePl.text}</p>
              </div>
              <ImageContainer className="d-none d-md-block">
                <img src={ImgPhoneHand} alt="Phone" />
              </ImageContainer>
            </SectionStyled>
          </Col>
        </Row>
      </div>
    </ContainerStyled>
  );
};

export default Platform;
