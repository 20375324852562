import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import styled from "styled-components";
import { FaQuoteLeft } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import { breakpoints } from "../../../utils";
import Section from "../../../components/Section";

const BgContainer = styled.div`
  position: relative;
`;

const QuoteContainer = styled.div`
  content: "";
  position: absolute;
  top: -3rem;
  left: 0;
  width: 100%;

  @media (max-width: ${breakpoints.md}px) {
    text-align: center;
    top: -2.5rem;
  }
`;

const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfSeller {
        acf {
          testimonial {
            customer_location
            customer_name
            customer_quote
            customer_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const testimonial = data.wordpressAcfSeller.acf.testimonial;
  const customerImage = testimonial.customer_image.localFile.childImageSharp.fluid;

  return (
    <Section>
      <Container>
        <BgContainer className="bg-white rounded-lg shadow-lg overflow-hidden">
          <Row className="">
            <Col lg="7" className="">
              <div className="d-flex flex-column p-5 justify-content-center h-100">
                <div className="mb-4 pb-3 position-relative">
                  <QuoteContainer>
                    <FaQuoteLeft size="1.5rem" className="text-secondary" />
                  </QuoteContainer>
                  <h3
                    className="text-info text-md-left text-center"
                    css={`
                      font-size: 32px;
                    `}
                  >
                    {testimonial.customer_quote}
                  </h3>
                </div>
                <div className="text-md-left text-center">
                  <p className="text-primary mb-1">
                    {testimonial.customer_name}
                  </p>
                  <p className="text-muted"> {testimonial.customer_location}</p>
                </div>
              </div>
            </Col>
            <Col lg="5">
              <div className="">
                <Img
                  fluid={customerImage}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt="Testimonial"
                />
              </div>
            </Col>
          </Row>
        </BgContainer>
      </Container>
    </Section>
  );
};

export default Testimonial;
