import React from "react";
import styled from "styled-components";
import { theme } from "../../utils";

const BadgeStyled = styled.div`
  display: inline-block;
  border-radius: 15px;
  text-align: center;
  padding: 2px 12px;
  white-space: nowrap;
  text-overflow: clip;
  text-transform: initial;
  letter-spacing: initial;
`;

const Badge = ({
  inverse = false,
  bg = theme.dark,
  color = theme.secondary,
  children,
  ...rest
}) => {

  const bgColor = inverse ? color : bg;
  const Textcolor = inverse ? bg : color;

  return (
    <BadgeStyled
      {...rest}
      css={`
        background: ${bgColor};
        color: ${Textcolor};
      `}
    >
      {children}
    </BadgeStyled>
  );
};

export default Badge;
