import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import defaultImage from '../../assets/images/banner-bg.png'

const SEO = ({
  title = null,
  description = null,
  image = null,
  pathname = null,
  article = false
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultDescription }
      },
      wordpressAcfCommon: {
        acf: { defaultTitle, siteUrl }
      }
    }) => {
      const seo = {
        title:
          title === "/" || title === ""
            ? `${title || defaultTitle}`
            : `${defaultTitle} - ${title}`,
        description: description || defaultDescription,
        image: `${image || defaultImage}`,
        url: pathname === "" ? `${siteUrl}` : `${siteUrl}/${pathname}`
      };

      const twitterUsername = "wemena"
      const titleTemplate = ""

      return (
        <>
        
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <html lang="en"/>
            <link rel="canonical" href={seo.url} />

            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {(article ? false : true) && (
              <meta property="og:type" content="website" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}

            {/* Wistia */}
            <script src="https://fast.wistia.com/embed/medias/e7erzh3z7x.jsonp" async></script><script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>

          </Helmet>
        </>
      );
    }}
  />
);

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultDescription: description
      }
    }

    wordpressAcfCommon {
      acf {
        defaultTitle: site_name
        siteUrl: site_url
      }
    }
  }
`;
