import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Section from "../../../components/Section";
import { theme, breakpoints } from "../../../utils";
import ImgDevice from "../../../assets/images/device.png";
import ImgAppStore from "../../../assets/images/app-store-badge.png";
import ImgPlayStore from "../../../assets/images/google-play-badge.png";

import Img from "gatsby-image";

const SectionStyled = styled(Section)`
  background: ${theme.secondary};
`;

const ImageContainer = styled.div`
  position: relative;
  bottom: -80px;
  margin-top: -70px;
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    position: relative;
    //bottom: -140px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    position: absolute;
    left: 0;
    bottom: 50px;
    //bottom: -160px;
    margin-right: -100px;
  }
`;

const CTA = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          cta {
            appstore_link
            playstore_link
            text
            title
          }
        }
      }
      appstoreImage: file(relativePath: { eq: "app-store-badge2.png" }) {
        id
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      playstoreImage: file(relativePath: { eq: "google-play-badge2.png" }) {
        id
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      deviceImage: file(relativePath: { eq: "device.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const cta = data.wordpressAcfHome.acf.cta;

  return (
    <>
      <SectionStyled className="overflow-hidden">
        <Container>
          <Row>
            <Col lg="6">
              <div className="mb-3 text-lg-left text-center">
                <div className="pr-lg-5 mb-4">
                  <h3
                    className="text-info text-lg-left text-center"
                    css={`
                      font-size: 32px;
                    `}
                  >
                    {cta.title}
                  </h3>
                </div>
                <p>{cta.text}</p>
                <Row>
                  <Col lg="6" sm="12">
                    <a
                      href={cta.appstore_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        css={`
                          height: "80px";
                        `}
                        fixed={data.appstoreImage.childImageSharp.fixed}
                        alt="WeMENA iOS AppStore"
                      />
                    </a>
                  </Col>
                  <Col lg="6" sm="12">
                    <a
                      href={cta.playstore_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        fixed={data.playstoreImage.childImageSharp.fixed}
                        alt="WeMENA Google Play Android Store"
                      />
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg="6">
              <ImageContainer className=" text-lg-right text-center">
                <Img
                  className="img-fluid"
                  fluid={data.deviceImage.childImageSharp.fluid}
                  alt="WeMENA on mobile and desktop"
                />
              </ImageContainer>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default CTA;
