import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Section from "../../../components/Section";
import { theme } from "../../../utils";
import FAQAccordions from "./FAQAccordions";

const SectionStyled = styled(Section)`
  /* background: ${theme.secondary}; */
`;

const Brands = () => {
  return (
    <>
      <SectionStyled
        css={`
          padding-top: 50px;
        `}
      >
        <Container className="pt-5">
          <Row>
            <Col lg="6">
              <div className="pr-lg-5 mb-5">
                <h3
                  className="text-info text-lg-left text-center"
                  css={`
                    font-size: 32px;
                  `}
                >
                  Frequently Asked Questions
                </h3>
              </div>
            </Col>
            <Col lg="6">
              <div className="mt-5 mt-lg-0">
                <FAQAccordions />
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Brands;
