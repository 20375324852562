import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { rgba } from "polished";
import { FaStar, 
  //FaSearch 
} from "react-icons/fa";
import Img from "gatsby-image";
import {
  Container,
  Card,
  Row,
  Col,
  //FormControl,
  //Button
} from "react-bootstrap";
import Rating from "react-rating";
import Section from "../../../components/Section";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { theme } from "../../../utils";
import Bar from "../../../components/Bar";
import Badge from "../../../components/Badge";

const SectionStyled = styled(Section)`
  background: linear-gradient(to bottom, #fff, #f2f4f6);
  overflow: hidden;
  position: relative;
`;

const CardContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
`;

const BadgeContainer = styled.div`
  position: absolute;
  left: 80%;
  top: -14px;
  z-index: 1;
`;

// const InputContainer = styled.div`
//   position: relative;
//   .form-control {
//     height: calc(1.5em + 0.75rem + 10px);
//     padding-left: 50px;
//   }
// `;
// const FaSearchStyled = styled(FaSearch)`
//   position: absolute;
//   top: 50%;
//   left: 1rem;
//   transform: translateY(-50%);
//   z-index: 1;
// `;
// const ButtonStyled = styled(Button)`
//   position: absolute;
//   top: 50%;
//   right: 0.5rem;
//   transform: translateY(-50%);
//   z-index: 1;
//   background-color: ${theme.btn} !important;
//   border: 1px solid ${theme.btn} !important;
//   padding: 0.35rem 1rem !important;
// `;

const Banner = ({data}) => {

  const productData = useStaticQuery(graphql`
    query {
      allWordpressAcfProduct {
        nodes {
          acf {
            price
            size
            title
            image {
              localFile {
                childImageSharp {
                  fixed(width: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }

    }
  `);
//console.log('from banner.js');
//console.log(data);
//console.log(productData);
  const title = data.banner.title;

  const text = data.banner.text;

  const bannerLink = data.banner.link ? data.banner.link : "https://www.wemena.com";

  const allProducts = productData.allWordpressAcfProduct.nodes;

  const bannerProducts = data.products;

  const productsArr = allProducts.filter(pr => {
    const prTitle = pr.acf.title;
    return bannerProducts.filter(prB => prB.post_title === prTitle).length > 0;
  });

  return (
    <>
      <SectionStyled>
        <CardContainer
          className="d-none d-lg-block"
          css={`
            overflow: hidden;
            padding: 70px 0;
          `}
        >
          <div>
           
            <Row>
              {productsArr.map(({ acf: product }, i) => (
                <Col md="4" key={i}>
                  <Card className="mb-4 mb-md-0">
                    <div
                      className="d-flex justify-content-center align-items-center p-3"
                      css={`
                        height: 150px;
                      `}
                    >
                      <Img
                        alt={product.title}
                        fixed={product.image.localFile.childImageSharp.fixed}
                      />
                    </div>
                    <Card.Body>
                      <Bar />
                      <Bar width="50%" className="my-2" />
                      <Bar bg={theme.primary} width="25%" />
                      <div className="mt-3">
                        <Rating
                          initialRating={4.5}
                          readonly
                          emptySymbol={
                            <FaStar color={rgba(theme.dark, 0.125)} />
                          }
                          fullSymbol={<FaStar color="#F5BB41" />}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </CardContainer>
        <Container>
          <Row>
            <Col lg="6">
              {/* <div className="h-100 d-flex flex-column justify-content-center pr-lg-5">
                <InputContainer>
                  <FaSearchStyled />
                  <FormControl
                    className="border-0 shadow-lg"
                    type="text"
                    placeholder="Search"
                  />
                  <ButtonStyled variant="info" size="sm">
                    Find
                  </ButtonStyled>
                </InputContainer>
              </div> */}
            </Col>
            <Col lg="6">
              <div className="h-100 d-flex flex-column justify-content-center p-5">
                <h1
                  css={`
                    font-size: 45px;
                    line-height: 55px;
                  `}
                >
                  {title}
                </h1>
                <p className="mb-5 mt-3">{text}</p>

                <div>
                  <a href={bannerLink}>
                  <ButtonPrimary className="position-relative">
                    <BadgeContainer className="d-none d-sm-block">
                      <Badge inverse>1k already joined</Badge>
                    </BadgeContainer>
                    View Marketplace
                  </ButtonPrimary>
                  </a>
                </div>

                <p className="text-muted mt-4">
                  100% Secure and Free. No Credit Card Required.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Banner;

// export const query = graphql`
//   query($id: Int!) {
//     wordpressAcfLanding(wordpress_id: { eq: $id }) {
//         acf {
//             meta {
//                 title
//                 description
//                 slug
//             }
//             banner {
//               text
//               title
//             }
//             products {
//               post_title
//             }
//         }
//         id
        
//     }
//   }
// `
