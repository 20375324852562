import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import {  breakpoints } from "../../utils";

const ButtonStyled = styled(Button)`
  &.btn-main {
    border-color: transparent !important;
    box-shadow: none !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0.8rem 1rem;
    @media (min-width: ${breakpoints.md}px) {
      letter-spacing: 3px;
      padding: 1.115rem 2.25rem;
    }
    border-radius: 35px;
    white-space: nowrap;
    text-overflow: clip;
  }
`;

const ButtonArrow = ({ className, children, ...rest }) => {
  return (
    <ButtonStyled
      className={`btn-main text-secondary bg-dark ${className}`}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

export default ButtonArrow;
