import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { FaPlay } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import Section from "../../../components/Section";
import BGVideo from "../../../assets/images/videoBG.png";

const SectionStyled = styled(Section)`
  background: linear-gradient(to bottom, #fff, #f2f4f6);

  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(${BGVideo});
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.55);
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    background-blend-mode: darken;
  }
`;

const ButtonPlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  cursor: pointer;
`;

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          video {
            title
            youtube_video_url
          }
        }
      }
    }
  `);

  const video = data.wordpressAcfHome.acf.video;

  return (
    <>
      <SectionStyled className="mt-5">
        <Container>
          <div
            css={`
              z-index: 50;
              position: relative;
            `}
          >
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <p
                  className="text-center text-secondary text-uppercase mb-4"
                  css={`
                    letter-spacing: 3px;
                  `}
                >
                  To watch
                </p>
                <h2
                  className="text-center text-white"
                  css={`
                    font-size: 45px;
                    line-height: 55px;
                  `}
                >
                  {video.title}
                </h2>
                <div className="mt-5 d-flex justify-content-center">
                  <a
                    href={video.youtube_video_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonPlay className="border border-secondary">
                      <FaPlay className="text-secondary" />
                    </ButtonPlay>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Banner;
