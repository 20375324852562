import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Container, Row, Col, Card } from "react-bootstrap";
import Badge from "../../../components/Badge";
import Section from "../../../components/Section";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { breakpoints} from "../../../utils";
import CatBG from "../../../assets/images/catBG.png";


const SectionStyled = styled(Section)`
  margin: -100px 0 0;
  padding-bottom: 100px;
  @media (min-width: ${breakpoints.md}px) {
    padding-bottom: 130px;
  }
  @media (min-width: ${breakpoints.lg}px) {
    padding-bottom: 170px;
  }
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    width: 100%;
    height: calc(17vh);
    background: url(${CatBG}) center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: -20px;
  }
  &:before {
    content: "";
    width: 100%;
    height: calc(17vh);
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      )
      center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /* margin-bottom: -25px; */
    z-index: 1;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  z-index: 1;
  margin-top: -100px;
`;


const Categories = () => {
  
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          categories {
            title
            total_products
            image {
              localFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const categories = data.wordpressAcfHome.acf.categories;
  return (
    <Container className="">
      <SectionStyled className="bg-white rounded-lg shadow-lg" inside>
        <div
          css={`
            position: relative;
            z-index: 10;
          `}
        >
          <div className="pr-lg-5 mb-5 pb-3">
            <h3
              className="text-info text-lg-left text-center"
              css={`
                font-size: 32px;
              `}
            >
              Top Categories in UAE
            </h3>
          </div>
          <Row className="my-5">
            {categories.map((item, i) => (
              <Col xl="4" key={i}>
                <Card className="border-0 mb-5 px-3">
                  <div className="d-flex">
                    <div
                      className="mr-4 flex-shrink-0"
                   
                    >
                    <Img fixed={item.image.localFile.childImageSharp.fixed} alt={item.title}/>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <Card.Title>{item.title}</Card.Title>
                      <Badge>{item.total_products} products</Badge>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}

            
          </Row>
        </div>
        <ButtonContainer className="text-center">
          <a href="https://www.wemena.com">
          <ButtonPrimary
            css={`
              position: relative;
              z-index: 100;
            `}
          >
            View All Categories
          </ButtonPrimary>
          </a>
        </ButtonContainer>
      </SectionStyled>
    </Container>
  );
};

export default Categories;
