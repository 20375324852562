import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Section from "../../../components/Section";
import BGImg from "../../../assets/images/bgCallback.png";
import { navigate } from 'gatsby-link'

const BgContainer = styled.div`
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(${BGImg});
    background-position: center;
    background-blend-mode: screen;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;

  .input-wemena {
    border: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    padding: 1.25rem 0 !important;
    color: #fff;
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &:focus {
      color: #fff;
    }
  }

  .select-wemena {
    border: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background: transparent !important;
    padding-left: 0;
    color: rgba(255, 255, 255, 0.5) !important;
    & option {
      color: #000 !important;
    }
  }
`;

const ButtonStyled = styled(Button)`
  border-radius: 2rem !important;
  padding: 0.75rem 2rem !important; 
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Callback = () => {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <>
      <Container>
        <BgContainer className="rounded-lg shadow-lg overflow-hidden bg-info">
          <Section inside>
            <Content className="text-white text-center">
              <h3
                css={`
                  font-size: 32px;
                `}
              >
                Request a call-back
              </h3>

              <form method="post" name="salesenquiries" netlify-honeypot="bot-field" data-netlify="true" action="/thanks" onSubmit={handleSubmit}>

                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />

                <input type="hidden" name="bot-field" />

                

                <p className="my-5">
                  Sign up now to speak to one of our specialists and learn more
                  about WeMENA.
                </p>
                <Row className="justify-content-end">
                  <Col lg="4">
                    <Form.Control
                      type="text"
                      name="name"
                      className="input-wemena mb-4"
                      placeholder="Your Name"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg="8">
                    <Form.Control
                      type="email"
                      name="email"
                      className="input-wemena mb-4"
                      placeholder="your@email.com"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg="8">
                    <Form.Control
                      type="tel"
                      name="phone"
                      className="input-wemena mb-4"
                      placeholder="+971 55 123 1234"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-end mt-lg-5">
                  <Col lg="4">
                    <Form.Control
                      type="text"
                      name="company"
                      className="input-wemena mb-4"
                      placeholder="Company Name"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg="8">
                    <Form.Control
                      as="select"
                      name="industry"
                      className="select-wemena mb-4"
                      onChange={handleChange}
                    >
                      <option>Choose Industry</option>
                      <option>Choose</option>
                      <option>Aerospace</option>
                      <option>Transport</option>
                      <option>Computer</option>
                      <option>Telecommunication</option>
                      <option>Agriculture</option>
                      <option>Construction</option>
                      <option>Education</option>
                      <option>Pharmaceutical</option>
                      <option>Food</option>
                      <option>Health care</option>
                      <option>Hospitality</option>
                      <option>Entertainment</option>
                      <option>News Media</option>
                      <option>Energy</option>
                      <option>Manufacturing</option>
                      <option>Music</option>
                      <option>Mining</option>
                      <option>Worldwide web</option>
                      <option>Electronics</option>

                    </Form.Control>
                  </Col>
                  <Col lg="8">
                    <Form.Control
                      type="text"
                      name="address"
                      className="input-wemena mb-4"
                      placeholder="Company Address"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <ButtonStyled className="mt-5" type="submit">SUBMIT</ButtonStyled>

              </form>


            </Content>
          </Section>
        </BgContainer>
      </Container>
    </>
  );
};

export default Callback;
