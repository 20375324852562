import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Logo = ({ footer, height, className = "" }) => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfCommon {
        acf {
          header_logo {
            localFile {
              childImageSharp {
                fixed(width: 170) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          footer_logo {
            localFile {
              childImageSharp {
                fixed(width: 180) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          site_name
        }
      }
    }
  `);

  const headerImg = data.wordpressAcfCommon.acf.header_logo.localFile.childImageSharp.fixed;
  const footerImg = data.wordpressAcfCommon.acf.footer_logo.localFile.childImageSharp.fixed;

  return (
    <div className={`${className}`}>
      <Img
        fixed={footer ? footerImg : headerImg}
        css={`
          height: ${height || "auto"};
        `}
        alt="WeMENA Logo"
      />
    </div>
  );
};

export default Logo;
