import React from 'react'
import {Container} from 'react-bootstrap'
import Section from '../components/Section'

const AboutPage = () => (
  <Section>
    <Container>
      <div>
        <h1>About Page</h1>
      </div>
    </Container>
  </Section>
);

export default AboutPage
