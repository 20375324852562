import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Container, Card, Row, Col } from "react-bootstrap";
import Section from "../../../components/Section";
import ButtonPrimary from "../../../components/ButtonPrimary";

const SectionStyled = styled(Section)`
  overflow: hidden;
  position: relative;
`;

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          features {
            text
            title
            image {
              localFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const features = data.wordpressAcfHome.acf.features;

  return (
    <>
      <SectionStyled>
        <Container>
          <div className="pr-lg-5 mb-5 pb-3 ">
            <h2
              className="text-info text-lg-left text-center"
              css={`
                font-size: 32px;
              `}
            >
              Purchasing made easy
            </h2>
          </div>
          <Row>
            {features.map((item, i) => (
              <Col md="6" lg="3" key={i}>
                <Card className="border-0 text-center mb-4 mb-md-0">
                  <div className="px-5 pb-3">
                    <Img fixed={item.image.localFile.childImageSharp.fixed} alt={item.title} />
                  </div>
                  <Card.Body className="pt-0">
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <div className="text-center">
          <a href="https://www.wemena.com">
          <ButtonPrimary
            css={`
              position: relative;
              z-index: 100;
            `}
          >
            View Marketplace
          </ButtonPrimary>
          </a>
          <p className="mt-3">
            Setup in <span className="text-primary">2 minutes</span>
          </p>
        </div>
      </SectionStyled>
    </>
  );
};

export default Features;
