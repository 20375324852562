import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Section from "../Section";
import Menu from "../Menu";
import Logo from "../Logo";

import VisaLogo from "../../assets/images/icon_visa.png";
import MastercardLogo from "../../assets/images/icon_mastercard.png";
import CashLogo from "../../assets/images/icon_cash.png";

const SectionStyled = styled(Section)`
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.6);
  a {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const Footer = () => {

  const data = useStaticQuery(graphql`
    query {
      wordpressAcfCommon {
        acf {
          site_name
        }
      }
    }
  `);

  const siteTitle = data.wordpressAcfCommon.acf.site_name;

  return (
    <>
      <SectionStyled className="border-top bg-info">
        <Container>
          <Row>
            <Col lg="6">
              <div className="">
                {/* <Menu className="d-flex" /> */}
              </div>
            </Col>
            <Col lg="6">
              <div className="d-flex justify-content-lg-end pt-3 pt-lg-0">
                
                  <Logo height="48px" footer />
   
              </div>
              <Row>
                <Col>
                    <div className="d-flex justify-content-lg-end pt-3 pt-lg-0 mt-2">                
                      <img src={VisaLogo} width="44px" height="31px" alt="We accept VISA" className="mr-3"/>
                      <img src={MastercardLogo} width="44px" height="31px" alt="We accept Mastercard" className="mr-3"/>
                      <img src={CashLogo} width="44px" height="31px" alt="We accept Cash on Delivery" className="mr-3"/>
                    </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="mt-5">
            &copy; {new Date().getFullYear()} {siteTitle}
          </div>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Footer;
