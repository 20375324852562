import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../components/Seo";

import Banner from "../sections/home/Banner";
import Features from "../sections/home/Features";
import Brands from "../sections/home/Brands";
import Categories from "../sections/home/Categories";
import WhyChoose from "../sections/home/WhyChoose";
import Video from "../sections/home/Video";
import Testimonial from "../sections/home/Testimonial";
import FAQ from "../sections/home/FAQ";
import CTA from "../sections/home/CTA";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          page_meta_title
          page_meta_description
        }
      }
    }
  `);

  //const meta = data.wordpressAcfHome.acf.meta;

  const metaTitle = data.wordpressAcfHome.acf.page_meta_title;
  const metaDesc  = data.wordpressAcfHome.acf.page_meta_description;

  return (
    <>
      <Seo
        title={metaTitle}
        pathname=""
        description={metaDesc}
      />
      
      
      <Banner />
      <Features />
      <Brands />
      <Categories />
      <WhyChoose />
      {/* <Video /> */}
      <Testimonial />
      <FAQ />
      <CTA />
    </>
  );
};
export default IndexPage;
