import React from "react";
import styled from "styled-components";
import { Nav } from "react-bootstrap";
import { Link } from "gatsby";
import { theme } from "../../utils";

const NavStyled = styled(Nav)`
  .nav-item {
    margin-right: 1.5rem;
  }
  .link {
    cursor: pointer;
    font-weight: 500;
    &.active {
      color: ${theme.primary};
    }
  }
`;

const Menu = ({ isFooter = false, onChangeLink = () => {}, ...rest }) => {
  const menuItemsNav = [
    { title: "Home", link: "/" },
    { title: "Terms", link: "/terms" },
    { title: "Return Policy", link: "/returns-policy" },
    { title: "Privacy Policy", link: "/privacy-policy" }
  ];

  const menuItems = menuItemsNav;

  return (
    <NavStyled {...rest}>
      {menuItems.map((item, index) => (
        <Nav.Item key={index} className="nav-item">
          <Link to={item.link} className="link" activeClassName="active">
            {item.title}
          </Link>
        </Nav.Item>
      ))}
    </NavStyled>
  );
};

export default Menu;
