import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Container, Card, Row, Col } from "react-bootstrap";
import Section from "../../../components/Section";

const SectionStyled = styled(Section)`
  overflow: hidden;
`;

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          features {
            text
            title
            image {
              localFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const features = data.wordpressAcfHome.acf.features;

  return (
    <>
      <SectionStyled>
        <Container>
          <Row>
            {features.map((item, i) => (
              <Col md="6" lg="3" key={i}>
                <Card className="border-0 text-center mb-4 mb-md-0">
                  <div
                    className="px-5 pb-3"
                  >
                    <Img fixed={item.image.localFile.childImageSharp.fixed} alt={item.title}/>
                  </div>
                  <Card.Body className="pt-0">
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>
                      {item.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Features;
