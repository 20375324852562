import React from 'react'
import {Container} from 'react-bootstrap'
import Section from '../components/Section'

const NotFoundPage = () => (
  <Section>
    <Container>
      <div>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Container>
  </Section>
);

export default NotFoundPage
