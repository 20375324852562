import React from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Header from "../Header";
import Footer from "../Footer";
import Seo from "../Seo";
import "./bootstrap-custom.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.main`
  flex: auto;
`;

const Layout = props => {

  const data = useStaticQuery(graphql`
    query {
      wordpressAcfCommon {
        acf {
          favicon {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  const faviconImg = data.wordpressAcfCommon.acf.favicon.localFile.url;

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={faviconImg} />

        {/* <script>
    {`
      ga('require', 'linker');
      ga('linker:autoLink', ['app.wemena.com']);
    `}
  </script> */}
      </Helmet>
      <Seo />
      <LayoutStyled>
        <Header />
        <MainStyled>{props.children}</MainStyled>
        <Footer />
      </LayoutStyled>
    </>
  );
};

export default Layout;
