import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { theme } from "../../utils";

const StyledBar = styled.div`
  height: 7px;
  border-radius: 7px;
`;

const Bar = ({ bg = rgba(theme.dark, 0.125), width = "100%", ...rest }) => {

  return (
    <StyledBar
      width={width}
      css={`
        width: ${width};
        background: ${bg};
      `}
      {...rest}
    />
  );
};

export default Bar;
