import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";
import { FaCaretRight } from "react-icons/fa";
import styled from "styled-components";
//import Slider from "./Slider";
import Section from "../../../components/Section";
import { theme } from "../../../utils";

const SectionStyled = styled(Section)`
  background: ${theme.secondary};
`;

const Brands = () => {

  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          state_info {
            title
            total_products
            total_retailers
            total_suppliers
            body
          }
        }
      }
      wordpressAcfSeller {
        acf {
          meta {
            slug
            title
          }
        }
      }
    }
  `);

  const stateInfo = data.wordpressAcfHome.acf.state_info;
  const sellerPage = data.wordpressAcfSeller.acf.meta;

  return (
    <>
      <SectionStyled
        className="pt-5"
        css={`
          padding-bottom: 175px;
        `}
      >
        {/* <Slider className="mb-5" /> */}
        <Container className="pt-5">
          <Row>
            <Col lg="6">
              <div className="pr-lg-5 mb-5">
                <h2
                  className="text-info text-lg-left text-center"
                  css={`
                    font-size: 32px;
                  `}
                >
                  {stateInfo.title}
                </h2>
                <p className="text-lg-left text-center"
                  css={`
                    margin-top:20px;
                    font-size: 16px;
                  `}>{stateInfo.body}</p>
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-5 text-lg-right text-center">
                <Row>
                  <Col lg="6" className="mb-5">
                    <div className="display-2 font-weight-bold text-white">
                      {stateInfo.total_suppliers}
                    </div>
                    <h3 className="text-info">of Suppliers</h3>
                    <div>
                      <Link to={`/${sellerPage.slug}`} className="text-primary">
                        <span>
                          <small>
                            <FaCaretRight />
                          </small>
                        </span>{" "}
                        Become a seller
                      </Link>
                    </div>
                  </Col>
                  <Col lg="6" className="mb-5">
                    <div className="display-2 font-weight-bold text-white">
                      {stateInfo.total_retailers}
                    </div>
                    <h3 className="text-info">of Retailers</h3>
                  </Col>
                  <Col lg="12">
                    <div className="display-2 font-weight-bold text-white">
                      {stateInfo.total_products}
                    </div>
                    <h3 className="text-info">Products added weekly</h3>
                    <div className="text-primary">Average Rating ⭑⭑⭑⭑⭑</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Brands;
