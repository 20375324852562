import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Seo from "../components/Seo";
import { Container, Row, Col } from "react-bootstrap";
import Section from "../components/Section";

const SectionStyled = styled(Section)`
  overflow: hidden;
  position: relative;
`;

const GeneralPage = ({data}) => {

  return (
    <>
      <Seo
        title={data.wordpressPage.title}
        pathname={data.wordpressPage.slug}
        description={data.wordpressPage.title}
      />
      
      <SectionStyled
        css={`
          padding-top: 50px;
        `}
      >
        <Container>
          <Row>
            <Col lg="12">
              <div className="pr-lg-5 mb-5">
                <h1
                  className="text-info text-lg-left text-center"
                  css={`
                    font-size: 32px;
                  `}
                >
                  {data.wordpressPage.title}
                </h1>
              </div>
           

              <div  className="mt-5 mt-lg-0"
                dangerouslySetInnerHTML={{
                  __html: data.wordpressPage.content
                }}></div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>

    </>
  );
};
export default GeneralPage;

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
        title
        content
        slug
    }
  }
`