// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-seller-js": () => import("./../src/templates/seller.js" /* webpackChunkName: "component---src-templates-seller-js" */),
  "component---src-templates-general-js": () => import("./../src/templates/general.js" /* webpackChunkName: "component---src-templates-general-js" */),
  "component---src-templates-landingmulti-js": () => import("./../src/templates/landingmulti.js" /* webpackChunkName: "component---src-templates-landingmulti-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */)
}

