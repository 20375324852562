import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../components/Seo";

import Banner from "../sections/seller/Banner";
//import Features from "../sections/seller/Features";
import Features from "../sections/home/Features"; //use the same as home for now
import Brands from "../sections/home/Brands";
import Platform from "../sections/seller/Platform";
import WhyChoose from "../sections/seller/WhyChoose";
import Video from "../sections/home/Video";
import Testimonial from "../sections/seller/Testimonial";
import Callback from "../sections/seller/Callback";
import FAQ from "../sections/seller/FAQ";
import CTA from "../sections/home/CTA";


const SellerPage = () => {

    const data = useStaticQuery(graphql`
      query {
        wordpressAcfSeller {
          acf {
            meta {
              description
              slug
              title
            }
          }
        }
      }
    `);

    const meta = data.wordpressAcfSeller.acf.meta;

  return (
    <>
      {/* Forced meta redirect to seller site */}
      <meta http-equiv="refresh" content="0;url=http://seller.wemena.com/" />
      <Seo
        title={meta.title}
        pathname={meta.slug}
        description={meta.description}
      />
      <Banner />
      <Features />
      <Brands />
      <Platform />
      <WhyChoose />
      {/* <Video /> */}
      <Testimonial />
      <Callback />
      <FAQ />
      <CTA />
    </>
  );
};
export default SellerPage;
