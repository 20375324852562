import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { rgba } from "polished";
import Img from "gatsby-image";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import styled from "styled-components";
import Section from "../../../components/Section";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { theme } from "../../../utils";

const SectionStyled = styled(Section)`
  background: ${theme.secondary};
  position: relative;
`;

const CardContainer = styled.div``;

const CardContainerShadow = styled.div`
  position: absolute;
  margin-top: -55px;
  width: 100%;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      ${rgba(theme.secondary, 0.25)},
      ${theme.secondary} 60%
    );
  }
`;

const ButtonStyled = styled(Button)`
  padding: 0.35rem 1rem !important;
  border-radius: 1rem !important;
  &.btn-fancy {
    background-color: ${theme.btn} !important;
    border: 1px solid ${theme.btn} !important;
  }
`;

const Catering = ({data}) => {
  
  const productData = useStaticQuery(graphql`
    query {
      allWordpressAcfProduct(sort: { fields: acf___title }) {
        nodes {
          acf {
            price
            size
            title
            url
            image {
              localFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const title = data.banner.title;
  const productsTitle = data.products_subtitle;

  const allProducts = productData.allWordpressAcfProduct.nodes;
  console.log(allProducts);

  const bannerProducts = data.products;
  console.log(bannerProducts);


  const productsArr = allProducts.filter(pr => {
    const prTitle = pr.acf.title;
    return bannerProducts.filter(prB => prB.post_title === prTitle).length > 0;
  });

  console.log(productsArr);

  
  return (
    <>
      <SectionStyled className="overflow-hidden">
        <Container>
          <div>
            <Row>
              <Col lg="8">
                <div className="mb-5 text-lg-left text-center">
                  <div className="pr-lg-5 mb-5 pb-3 ">
                    <h2
                      className="text-info text-lg-left text-center"
                      css={`
                        font-size: 32px;
                      `}
                    >
                      {/* Top {title} Products in UAE */}
                      {productsTitle}
                    </h2>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* <div className="mb-3">
            <ButtonStyled variant="dark" size="sm" className="mr-2 mb-2">
              Subcategory (123)
            </ButtonStyled>
            <ButtonStyled variant="secondary" size="sm" className="mr-2 mb-2">
              Subcategory (10)
            </ButtonStyled>
            <ButtonStyled variant="secondary" size="sm" className="mr-2 mb-2">
              Subcategory (23)
            </ButtonStyled>
            <ButtonStyled variant="secondary" size="sm" className="mr-2 mb-2">
              Subcategory (30)
            </ButtonStyled>
            <ButtonStyled variant="secondary" size="sm" className="mr-2 mb-2">
              Subcategory (5)
            </ButtonStyled>
            <ButtonStyled variant="secondary" size="sm" className="mr-2 mb-2">
              View All
            </ButtonStyled>
          </div> */}
          <CardContainer>
            <Row>
              {productsArr.map(({ acf: product }, i) => (
                <Col md="4" key={i}>
                  <Card className="mb-4 mb-md-0">
                    <div
                      className="d-flex justify-content-center align-items-center p-3"
                      css={`
                        height: 150px;
                      `}
                    >
                      <Img
                        alt={product.title}
                        fixed={product.image.localFile.childImageSharp.fixed}
                      />
                    </div>
                    <Card.Body>
                      <strong className="d-inline-block mb-2">{product.title} </strong>
                      <br />
                      <span>Size: {product.size}</span>
                    </Card.Body>

                    <div className="text-center mb-4">
                      <a href={product.url}>
                        <ButtonStyled
                          variant="info"
                          size="sm"
                          className="btn-fancy"
                        >
                          View Product
                        </ButtonStyled>
                      </a>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </CardContainer>
          <div className="text-center mt-5">
            <a href="https://www.wemena.com/register">
              <ButtonPrimary
                css={`
                  position: relative;
                  z-index: 100;
                `}
              >
                Signup to view products
              </ButtonPrimary>
            </a>
          </div>
        </Container>
        <CardContainerShadow>
          <Container>
            <Row>
              {allProducts.map(({ acf: product }, i) => (
                <Col md="4" key={i}>
                  <Card className="mb-4 mb-md-0">
                    <div
                      className="d-flex justify-content-center align-items-center p-3"
                      css={`
                        height: 150px;
                      `}
                    >
                      <Img
                        alt={product.title}
                        fixed={product.image.localFile.childImageSharp.fixed}
                      />
                    </div>
                    <Card.Body>
                      Black Ripple Wrap Hot Coffee Cup <br />
                      Size: 16Oz
                    </Card.Body>

                    <div className="text-center mb-4">
                      <ButtonStyled
                        variant="info"
                        size="sm"
                        className="btn-fancy"
                      >
                        Sign-up to see price
                      </ButtonStyled>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </CardContainerShadow>
      </SectionStyled>
    </>
  );
};

export default Catering;
