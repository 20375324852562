import React, { useState } from "react";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import { Container, Navbar } from "react-bootstrap";
import Headroom from "react-headroom";
import Logo from "../Logo";
import Offcanvas from "../Offcanvas";
import MenuBar from "../../assets/images/menu-bar.png";
import ButtonArrow from "../ButtonArrow";
import PlayVideo from "../PlayVideo";


const HeadroomStyled = styled(Headroom)`
  .headroom {
    z-index: 9999 !important;
  }
  .navbar-brand {
    font-weight: 600;
  }
`;

const TeamsMobileBar = styled.div`
  background-color:#01516F;
  color:#fff !important;
  padding:5px;
  text-align:center;

  a {
    color:white;
  }
`;

const NavButton = styled.div`
cursor: pointer;
`;

const NavbarTop = () => {
  const [isPinned, setIsPinned] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);


  const data = useStaticQuery(graphql`
    query {
      wordpressAcfSeller {
        acf {
          meta {
            slug
            title
          }
        }
      }

    }
  `);

  const sellerPage = data.wordpressAcfSeller.acf.meta;

  return (
    <>
      <Offcanvas
        className="border-right"
        show={showOffcanvas}
        onHideOffcanvas={() => setShowOffcanvas(false)}
      />

      <HeadroomStyled
        onPin={() => setIsPinned(true)}
        onUnfix={() => setIsPinned(false)}
      >
        <a href="https://teams.wemena.com" class="d-block d-md-none"><TeamsMobileBar>New! Team Buying</TeamsMobileBar></a>
        
        <Navbar
          className={`px-md-0 ${isPinned &&
            "bg-white border-bottom shadow-sm"}`}
        >
          <Container className="px-sm-3">
            <Navbar.Brand as={Link} to="/" className="my-3">
              <Logo />
            </Navbar.Brand>
            <div className="d-flex align-items-center">
              
              {/* <ButtonArrow className="mr-3 d-none d-md-block"> */}
                {/* <Link to={`/${sellerPage.slug}`}>Become a seller</Link> */}
                {/* <a href="https://teams.wemena.com">New! Team Buying</a> */}
              {/* </ButtonArrow> */}

              <PlayVideo className="mr-3 d-none d-md-block">
                <span class="wistia_embed wistia_async_e7erzh3z7x popover=true popoverAnimateThumbnail=true popoverContent=link">
                  <a href="#">Watch how it works</a>
                  </span>
              </PlayVideo>

              <ButtonArrow className="mr-5 d-none d-md-block">
                <a href="https://www.wemena.com/market" id="header_gotomarket">Go To Marketplace</a>
                {/* <GoogleCrossDomainLink linkText="Go To Marketplace"/> */}
              </ButtonArrow>

              <NavButton onClick={() => setShowOffcanvas(!showOffcanvas)}>
                <img src={MenuBar} alt="menubar" />
              </NavButton>
            </div>
          </Container>
        </Navbar>
      </HeadroomStyled>
    </>
  );
};

export default NavbarTop;
