import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col, Card } from "react-bootstrap";
import ButtonPrimary from "../../../components/ButtonPrimary";
import BgImg from "../../../assets/images/why-choose3.png";
import Section from "../../../components/Section";

import Img from "gatsby-image"

const SectionStyled = styled(Section)`
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  z-index: 1;
  margin-top: -32px;
`;



const Categories = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressAcfHome {
        acf {
          choose_items {
            title
            list_items {
              item_text
            }
          }
        }
      }
      arabImage: file(relativePath: {eq: "why-choose3.png"}) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);


  const chooseItems = data.wordpressAcfHome.acf.choose_items;

  return (
    <Section>
      <Container className="">
        <SectionStyled className="bg-white rounded-lg shadow-lg" inside>
          <Row className="">
            <Col lg="6" className="pr-lg-5">
              <div className="pr-lg-5 mb-5 pb-3 ">
                <h3
                  className="text-info text-lg-left text-center"
                  css={`
                    font-size: 32px;
                  `}
                >
                  Why choose WeMENA?
                </h3>
              </div>
              <Card className="border-0 mb-5">    
                <div className="mr-4">
                  <Img 
                      fluid={data.arabImage.childImageSharp.fluid}
                      alt="Why choose WeMENA"
                    />
                </div>
              </Card>
            </Col>
            <Col lg="6">
              <Card className="border-0 mt-5">
                {chooseItems.map((item, i) => (
                  <div className="mb-4" key={i}>
                    <Card.Title className="mb-3">{item.title}</Card.Title>
                    <div>
                      {item.list_items.map((list, iInner) => (
                        <p key={iInner}>
                          <span className="mr-3 text-primary">+</span>{" "}
                          {list.item_text}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </Card>
            </Col>
          </Row>
          <ButtonContainer className="text-center">
            <a href="https://www.wemena.com">
              <ButtonPrimary
                css={`
                  position: relative;
                  z-index: 100;
                `}
              >
                View Marketplace
              </ButtonPrimary>
            </a>
            <p className="mt-3">
              Setup in <span className="text-primary">2 minutes</span>
            </p>
          </ButtonContainer>
        </SectionStyled>
      </Container>
    </Section>
  );
};

export default Categories;
