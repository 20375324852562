import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";

import Banner from "../sections/landing/Banner";
import Catering from "../sections/landing/Catering";
import Features from "../sections/landing/Features";
import CTA from "../sections/home/CTA";

const LandingPageMulti = ({data}) => {
   
//console.log(data);

const meta = data.wordpressAcfLanding.acf.meta;
const metaTitle = data.wordpressAcfLanding.acf.page_meta_title;
const metaDesc  = data.wordpressAcfLanding.acf.page_meta_description;

console.log(meta);
console.log(data.wordpressAcfLanding.acf);

  return (
    <>
      <Seo
        title={metaTitle}
        pathname={meta.slug}
        description={metaDesc}
      />
         
      <Banner data={data.wordpressAcfLanding.acf} />
      <Catering data={data.wordpressAcfLanding.acf}  />
      <Features />
      <CTA />
    </>
  );
};
export default LandingPageMulti;

export const query = graphql`
  query($id: Int!) {
    wordpressAcfLanding(wordpress_id: { eq: $id }) {
        acf {
            meta {
                title
                description
                slug
            }
            banner {
              text
              title
              link
            }
            products {
              post_title
            }
            products_subtitle
            page_meta_title
            page_meta_description
        }
        id
    }
  }
`